import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { ThemeProvider, createTheme } from '@mui/material'
import App from 'App'

const Index = () => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  )
}

export default Index
