import { useState } from 'react'
import { Button, CircularProgress, Typography } from '@mui/material'
import { getConfig } from 'utils'

const { PUBLIC_WEB_API_URL } = getConfig()

const App = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isUnsubscribed, setIsUnsubscribed] = useState(false)
  const [error, setError] = useState(false)

  const params = new URLSearchParams(window.location.search.replace('+', '%2B'))
  const email = params.get('email')
  const unsubscribedChannelId = params.get('unsubscribedChannelId')

  const handleUnsubscribe = async () => {
    setIsLoading(true)

    const data = {
      unsubscribedChannelId,
      email,
    }

    try {
      const response = await fetch(
        `${PUBLIC_WEB_API_URL}/kommunicera/avprenumerera`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
      if (response.ok) {
        setIsLoading(false)
        setIsUnsubscribed(true)
      } else {
        setIsLoading(false)
        setError(true)
      }
    } catch {
      setIsLoading(false)
      setError(true)
    }
  }

  if (!email || !unsubscribedChannelId || error) {
    return (
      <Typography>
        Något gick fel, prova att klicka på länken i mailet och att prova igen.
      </Typography>
    )
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return !isUnsubscribed ? (
    <Button
      data-testid="unsubscribe-button"
      onClick={() => void handleUnsubscribe()}
    >
      Avprenumerera
    </Button>
  ) : (
    <Typography>Klart! Din prenumeration är nu avslutad.</Typography>
  )
}

export default App
